@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

//@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/spinkit.css";
