.common-select {
  .Select-control,
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    border: 1px solid var(--borderColor) !important;
    background: var(--inputColor) !important;
    border-radius: 4px !important;
    color: var(--textColor) !important;
    min-height: 35px !important;
  }
  .css-vwja0k {
    color: var(--textColor) !important;
  }
}
.common-select.input-required {
  .Select-control {
    border: 1px solid var(--redColor) !important;
  }
}
.css-1pndypt-Input {
  color: var(--textColor) !important;
}
.css-1n7v3ny-option,
.css-9gakcf-option:hover {
  background-color: var(--activeColor) !important;
  cursor: pointer !important;
  color: var(--textColor) !important;
}
.css-9gakcf-option {
  background-color: transparent !important;
}
.css-1n7v3ny-option {
  cursor: pointer !important;
}

.css-9gakcf-option {
  color: var(--activeColor) !important;
}
.Select.is-disabled .Select-control {
  background-color: #3a4149 !important;
  opacity: 1;
}
.css-qc6sy-singleValue {
  color: var(--textColor) !important;
}
.Select-placeholder {
  text-align: left !important;
  padding-left: 7px !important;
}
.css-1rhbuit-multiValue {
  background-color: rgba(0, 126, 255, 0.08) !important;
  border: 1px solid rgba(0, 126, 255, 0.24) !important;
}
.css-12jo7m5 {
  color: var(--textColor) !important;
}

.Select.Select--single .Select-input {
  width: 100% !important;
  text-align: left;
}

.Select-menu-outer,
.css-26l3qy-menu {
  background: var(--fourthColor) !important;
  border: none !important;
  min-height: 7rem !important;
  color: var(--textColor) !important;
  margin-top: -5px !important;
  z-index: 99999 !important;
  .Select-menu {
    .Select-option {
      background: var(--fourthColor) !important;
      color: var(--textColor) !important;
      text-align: left !important;
    }
    .Select-option:hover {
      background: var(--activeColor) !important;
      color: var(--textColor) !important;
    }
  }
}

.supplier-select {
  .Select-menu-outer,
  .css-26l3qy-menu {
    max-height: 300px !important;
  }
  .Select-menu,
  .css-26l3qy-menu {
    max-height: 298px !important;
  }
}

.arranger-select .Select-menu-outer {
  width: 150% !important;
}
.arranger-section .css-b62m3t-container {
  width: 70%;
  float: left;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0px !important;
}

.Select--multi.is-disabled .Select-value {
  background-color: #ebf5ff !important;
  background-color: rgba(0, 126, 255, 0.08) !important;
  border: 1px solid #c2e0ff !important;
  border: 1px solid rgba(0, 126, 255, 0.24) !important;
  color: #007eff !important;
}

.Select-control .Select-input:focus,
.css-319lph-ValueContainer:focus {
  background: var(--fourthColor) !important;
}

.Select-input input {
  color: var(--textColor) !important;
}

.Select-value-label,
.css-6j8wv5-Input {
  color: var(--textColor) !important;
}
.css-1s2u09g-control {
  background: var(--fourthColor) !important;
  min-height: 35px !important;
}

.common-normal-select {
  border: 1px solid var(--secondColor) !important;
  background: var(--fourthColor) !important;
  border-radius: 4px !important;
  color: var(--textColor) !important;
  width: 40%;
  height: 35px;
  padding: 5px;
  margin-top: -5px;
  outline: none;
}

.common-select1 {
  .Select-control,
  .css-1s2u09g-control,
  .css-1pahdxg-control {
    border: none !important;
    background: #3a4149 !important;
    border-radius: 4px !important;
    color: var(--textColor) !important;
    min-height: 35px !important;
  }
  .css-vwja0k {
    color: var(--textColor) !important;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
  display: none !important;
}
}