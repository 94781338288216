.common-date-range {
  .DateRangePickerInput {
    background-color: var(--inputColor);
    border: 1px solid var(--secondColor);
    button {
      outline: none;
    }
  }

  .DayPicker__horizontal {
    width: 800px !important;
    height: 50vh !important;
  }

  .DateInput {
    width: 120px;
  }

  .DateInput_fang {
    top: 53px !important;
  }

  .DateInput_input {
    background-color: var(--inputColor);
    color: var(--textColor);
    font-weight: 400;
    font-size: 15px;
    padding: 5px 5px 5px;
  }

  .DateRangePickerInput_arrow {
    color: var(--textColor);
  }

  .DateRangePickerInput_calendarIcon_svg {
    fill: var(--textColor);
  }

  .CalendarDay__default:hover {
    background: #abe6fb;
    color: #484848;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: var(--activeColor);
    border-color: var(--activeColor);
  }

  .CalendarDay__selected_span {
    background-color: #abe6fb;
    border-color: #abe6fb;
    color: #484848;
  }

  .DateInput_input__focused {
    border: none;
  }

  .panelDiv {
    border-right: 1px solid #e4e7e7;
    height: 50vh;
    width: 150px;
    padding-top: 10px;

    .dateName {
      color: #484848;
      display: block;
      padding: 7px 10px;
      cursor: pointer;
      margin-bottom: 5px;
    }

    .dateName:hover,
    .active {
      color: #fff;
      background-color: var(--activeColor);
      border-color: var(--activeColor);
    }

    .bottomDiv {
      width: 630px;
      text-align: right;
      border-top: 1px solid #e4e7e7;
      position: absolute;
      right: 15px;
      bottom: 1vh;
      z-index: 999;
      padding-top: 5px;

      .bottomDates,
      .btnCancel {
        margin-right: 15px;
      }
      .bottomDates {
        color: var(--activeColor);
        font-weight: bold;
      }
    }
  }
}

.react-datepicker--time-only {
  width: 150px !important;
  .react-datepicker__triangle {
    left: 80px !important;
  }
  .react-datepicker__time-container,
  .react-datepicker__time-box {
    width: 100% !important;
  }
}
.common-date-range .DateRangePickerInput_calendarIcon {
  padding: 0px;
}
.account.common-date-range {
  position: absolute;
  top: 75px;
  right: 130px;
  z-index: 100;
}
